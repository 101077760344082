<template>
  <div class="rounded-lg shadow-lg p-2">
    <b-card>
      <h3>Add Exception</h3>
      <form-component :exception="exception" />
    </b-card>
  </div>
</template>

<script>
import FormComponent from "./components/FormComponent.vue";
import { BCard } from "bootstrap-vue";
export default {
  components: {
    BCard,
    FormComponent,
  },
  data() {
    return {
      exception: {
        user_id: "",
        customer_id: "",
        employee_id: "",
        date: "",
        reason: "",
      },
    };
  },
};
</script>

<style></style>
