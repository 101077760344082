<template>
  <div>
    <b-form>
      <validation-observer ref="simpleRules">
        <div class="row">
          <div
            v-if="loggedInUser.roles[0].name === 'superadmin'"
            class="col-md-6"
          >
            <label for="" class="label-font"
              >Subsidiary Company <span class="text-danger">*</span></label
            >

            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Subsidiary Company"
                rules="required"
              >
                <v-select
                  v-model="exception.company"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="companies"
                  label="name"
                  placeholder="Select Subsidiary Company"
                  @input="getCustomers"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div
            v-if="
              loggedInUser.roles[0].name === 'superadmin' ||
              loggedInUser.roles[0].name === 'admin'
            "
            class="col-md-6"
          >
            <label for="" class="label-font"
              >Client <span class="text-danger">*</span></label
            >
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Client"
                rules="required"
              >
                <v-select
                  v-model="exception.customer"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="customers"
                  label="name"
                  placeholder="Select Client"
                  @input="getEmployees"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div
            v-if="
              loggedInUser.roles[0].name === 'superadmin' ||
              loggedInUser.roles[0].name === 'admin' ||
              loggedInUser.roles[0].name === 'customer'
            "
            class="col-md-6"
          >
            <label for="" class="label-font"
              >Employee <span class="text-danger">*</span></label
            >
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Employee"
                rules="required"
              >
                <v-select
                  v-model="exception.employee"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="employees"
                  label="name"
                  placeholder="Select Employee"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <label for="" class="label-font"
              >Date <span class="text-danger">*</span></label
            >
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Date"
                rules="required"
              >
                <b-form-datepicker
                  v-model="exception.date"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Select Date"
                  locale="en"
                ></b-form-datepicker>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="col-md-12">
            <label for="" class="label-font"
              >Reaion <span class="text-danger">*</span></label
            >
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Reaion"
                rules="required"
              >
                <b-form-textarea
                  v-model="exception.reason"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter Reason"
                  rows="3"
                  max-rows="6"
                  id="textarea-large"
                ></b-form-textarea>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-center">
          <b-button @click.prevent="addException" type="submit" variant="info"
            >Submit</b-button
          >
        </div>
      </validation-observer>
    </b-form>
  </div>
</template>

<script>
import {
  BForm,
  BButton,
  BCard,
  BFormInput,
  BFormGroup,
  BFormDatepicker,
  BFormTextarea,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { email, required } from "@validations";
import vSelect from "vue-select";
import { getLogedInUser } from "@/utils/auth";
import { toast } from "@/utils/toast";
export default {
  components: {
    BForm,
    BButton,
    BCard,
    BFormInput,
    BFormGroup,
    BFormDatepicker,
    BFormTextarea,
    vSelect,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    exception: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loggedInUser: JSON.parse(getLogedInUser()),
      query: {
        company: "",
        customer: "",
        employee: "",
        page: 1,
        per_page: 1000,
      },
      companies: [],
      customers: [],
      employees: [],
    };
  },
  mounted() {
    if (this.loggedInUser.roles[0].name === "superadmin") {
      this.getCompanies();
    }
    if (this.loggedInUser.roles[0].name === "customer") {
      this.exception.customer = this.loggedInUser;
      this.getEmployees();
    }
    if (this.loggedInUser.roles[0].name === "admin") {
      this.exception.company = this.loggedInUser;
      this.getCustomers();
    }
    if (this.loggedInUser.roles[0].name === "employee") {
      this.exception.employee = this.loggedInUser;
    }
  },
  methods: {
    async getCompanies() {
      await this.$store
        .dispatch("company/getCompaniesForDropdown")
        .then((response) => {
          this.companies = response.data;
        });
      this.getCustomers();
    },
    async getCustomers() {
      if (this.exception.company && this.exception.company.id) {
        await this.$store
          .dispatch("customer/getCustomersForDropdown", {
            user_id: this.exception.company.id,
          })
          .then((response) => {
            this.customers = response.data;
            this.getEmployees();
          });
      }
    },
    getEmployees(e) {
      if (this.loggedInUser.roles[0].name == "superadmin") {
        this.exception.company_id = this.exception?.company?.id;
        this.exception.customer_id = this.exception?.customer?.id;
      }
      if (this.loggedInUser.roles[0].name == "admin") {
        this.exception.company_id = this.loggedInUser.id;
      }
      if (this.loggedInUser.roles[0].name == "customer") {
        this.exception.customer_id = this.loggedInUser.id;
      }
      if (e) {
        this.query.page = e;
      }
      let query = {
        page: this.query.page,
        per_page: this.query.per_page,
        company_id: this.exception?.company_id,
        customer_id: this.exception?.customer_id,
      };
      this.$store
        .dispatch("employee/getEmployees", query)
        .then((res) => {
          this.employees = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addException() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          const data = {
            user_id: this.exception?.employee?.id,
            customer_id: this.exception?.customer?.id,
            employee_id: this.exception?.employee?.id,
            date: this.exception?.date,
            reason: this.exception?.reason,
          };
          this.$store
            .dispatch("roster/addException", data)
            .then((response) => {
              if (response.status_code == 200) {
                toast(
                  "Success",
                  "CheckCircleIcon",
                  "success",
                  response.message || "Exception added successfully"
                );
                this.$router.push({ name: "exceptions" });
              }
            })
            .catch((error) => {
              let errorResponse = error.response?.data?.errors;
              for (const key in errorResponse) {
                if (errorResponse.hasOwnProperty(key)) {
                  const errorMessages = errorResponse[key];
                  // Loop through each error message in the array
                  for (const errorMessage of errorMessages) {
                    toast("Error", "AlertTriangleIcon", "danger", errorMessage);
                  }
                }
              }
            });
        } else {
          toast(
            "Error",
            "AlertTriangleIcon",
            "danger",
            "Please fill all the required fields"
          );
        }
      });
    },
  },
};
</script>

<style scoped></style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
